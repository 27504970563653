import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import { Box, CardContent, Paper, Typography } from '@mui/material';
import ReactPlayer from 'react-player';

const classes = {
  headingTitle: {
    fontFamily: 'Rubik Doodle Shadow',
    fontSize: '48px',
    font: 'gold'
  }
}

const defaultTheme = createTheme();

export default function Home() {

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Header title="old time & bluegrass string band"/>
        <main>
          <Paper sx={{width: '100%'}} >
              <Typography gutterBottom variant="h5" align='center' sx={[classes.headingTitle, {fontSize: '48px'}]} >
                The Stinging Nettles String Band
              </Typography>
              <Box alignItems={'center'} flexDirection={'column'} sx={{display: 'flex'}}>
                  <ReactPlayer
                      className='react-player'
                      url='https://www.youtube.com/watch?v=cpD6ZLP3TC8'
                      width='80%'
                  />
                        <CardContent>
                  <Typography gutterBottom variant="h5" >
                    Upcoming Events...
                  </Typography>
                  <Typography gutterBottom variant="h6" >
                    May 25 - KC Cross Currents Barn Dance
                  </Typography>
                </CardContent>
              </Box>
          </Paper>
        </main>
      </Container>
    </ThemeProvider>
  );
}